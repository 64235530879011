import { AlertColor } from "@mui/material";
import React from "react";

type GlobalContextType = {
  theme: boolean | (() => boolean);
  setTheme: React.Dispatch<React.SetStateAction<boolean>>;
  toastMessage?: string;
  toastType?: AlertColor;
  toastDuration?: number | null;
  showToast: (message: string, type?: AlertColor) => void;
  hideToast: () => void;
};

export const GlobalContext = React.createContext<GlobalContextType | undefined>(
  undefined,
);
