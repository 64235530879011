import React from "react";
import FilterTable from "./FilterTable";
import { TableSearch } from "./TableSearch";

export interface TableCategory {
  category: string;
  minW?: number | string;
  withSearch?: boolean;
  withFilter?: {
    value: string | number;
    label: string | number;
  }[];
}

function TableHeaderCategories({
  tableHeaderCategories,
  showActionButtons = true,
  showSearchForCategories = true,
  handleCategoryPress,
  setTableSearch,
}: {
  tableHeaderCategories: TableCategory[];
  showActionButtons?: boolean;
  showSearchForCategories?: boolean;
  handleCategoryPress?: (name: string, event?: any) => void;
  setTableSearch?: (value: object) => void;
}) {
  return (
    <thead>
      <tr className="fw-bolder text-muted bg-light">
        {tableHeaderCategories.map(({ category, minW }, idx) => {
          return (
            <th
              key={idx}
              className={`${handleCategoryPress && "cursor-pointer"} 
              min-w-${minW ?? "130"}px
              ${idx === 0 && "ps-4 rounded-start"}`}
              onClick={
                handleCategoryPress && (() => handleCategoryPress(category))
              }>
              {category}
            </th>
          );
        })}
        {showActionButtons && (
          <th className="min-w-150px text-end rounded-end"></th>
        )}
      </tr>
      {showSearchForCategories && (
        <>
          <tr style={{ height: 20 }} />
          <tr className="fw-bolder text-muted bg-light">
            {tableHeaderCategories.map(
              ({ category, minW, withSearch, withFilter }, idx) => {
                return (
                  <th
                    key={idx}
                    className={`min-w-${minW ?? "130"}px ${
                      idx === 0 && "ps-4 rounded-start"
                    }`}>
                    {withSearch && setTableSearch && (
                      <TableSearch
                        category={category}
                        setTableSearch={setTableSearch}
                      />
                    )}
                    {withFilter && setTableSearch && (
                      <FilterTable
                        category={category}
                        withFilter={withFilter}
                        minW={minW}
                        setTableSearch={setTableSearch}
                      />
                    )}
                  </th>
                );
              },
            )}
            {showActionButtons && (
              <th className="min-w-150px text-end rounded-end"></th>
            )}
          </tr>
        </>
      )}
    </thead>
  );
}

export default TableHeaderCategories;
