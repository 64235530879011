import "../assets/sass/style.scss";
import "../assets/sass/style.react.scss";

import MainRoutes from "../router";
import GlobalProvider from "../context/GlobalContext/GlobalProvider";
import ThemeWrapper from "../components/ThemeWrapper/ThemeWrapper";

import { Provider } from "react-redux";
import { store } from "../redux/store/store";
import { LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <ThemeWrapper>
          <GlobalProvider>
            <MainRoutes />
          </GlobalProvider>
        </ThemeWrapper>
      </Provider>
    </LocalizationProvider>
  );
}

export default App;
