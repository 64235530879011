import React, { useEffect, useState } from "react";
import { useDebounce } from "../../../hooks/useDebounce";

function FilterTable({
  category,
  withFilter,
  minW,
  setTableSearch,
}: {
  category: string;
  minW?: number | string;
  withFilter: {
    value: string | number;
    label: string | number;
  }[];
  setTableSearch?: (value: object) => void;
}) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        // updateState({search: debouncedSearchTerm, ...initialQueryState})
        setTableSearch &&
          setTableSearch((prev: any) => ({
            ...prev,
            [category]: debouncedSearchTerm,
          }));
      }
    },
    [debouncedSearchTerm], // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  );

  return (
    <div className="card-title">
      <select
        name={category}
        data-control={`select-${category}`}
        data-hide-search="true"
        className={`form-select form-select-sm form-select-solid bg-white min-w-${
          minW ?? "130"
        }px`}
        defaultValue={withFilter[0].value}
        onChange={(e) => setSearchTerm(e.target.value)}>
        {withFilter.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default FilterTable;
