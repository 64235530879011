import { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import clsx from "clsx";
import { Formik } from "formik";
import { logginUser } from "../../redux/actions/authAction";
import Text from "../../styleguide/Text";
import { LoadingButton } from "@mui/lab";
import { Container } from "@mui/material";

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Username is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  username: "admin@demo.com",
  password: "demo",
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center h-100">
      <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <Formik
          initialValues={initialValues}
          validationSchema={loginSchema}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setLoading(true);
            setTimeout(() => {
              dispatch(logginUser(values.username, values.password));
              setLoading(false);
              setSubmitting(false);
              setStatus("The login detail is incorrect");
            }, 1000);
          }}>
          {({
            status,
            isValid,
            errors,
            touched,
            getFieldProps,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form
              className="form w-100"
              onSubmit={handleSubmit}
              noValidate
              id="kt_login_signin_form">
              {/* begin::Heading */}
              <div className="text-center mb-10">
                <Text variant="body1" className="text-dark mb-3">
                  Login
                </Text>
              </div>
              {/* begin::Heading */}

              {status ? (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">{status}</div>
                </div>
              ) : (
                <div className="mb-10 bg-light-info p-8 rounded">
                  <Text variant="body1" className="text-info">
                    Add your account
                  </Text>
                </div>
              )}

              {/* begin::Form group */}
              <div className="fv-row mb-10">
                <label
                  htmlFor="username"
                  className="form-label fs-6 fw-bolder text-dark">
                  <Text variant="body1" isBold>
                    Username
                  </Text>
                </label>
                <input
                  placeholder="UserName"
                  {...getFieldProps("username")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    {
                      "is-invalid": touched.username && errors.username,
                    },
                    {
                      "is-valid": touched.username && !errors.username,
                    },
                  )}
                  type="username"
                  autoComplete="off"
                />
                {touched.username && errors.username && (
                  <div className="fv-plugins-message-container">
                    <Text variant="body1" role="alert" className="mt-2">
                      {errors.username}
                    </Text>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group */}
              <div className="fv-row mb-10">
                <div className="d-flex justify-content-between mt-n5">
                  <div className="d-flex flex-stack mb-2">
                    {/* begin::Label */}
                    <label
                      htmlFor="username"
                      className="form-label fs-6 fw-bolder text-dark">
                      <Text variant="body1" isBold>
                        Password
                      </Text>
                    </label>
                    {/* end::Label */}
                  </div>
                </div>
                <input
                  type="password"
                  autoComplete="off"
                  {...getFieldProps("password")}
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    {
                      "is-invalid": touched.password && errors.password,
                    },
                    {
                      "is-valid": touched.password && !errors.password,
                    },
                  )}
                />
                {touched.password && errors.password && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <Text variant="body1" role="alert" className="mt-2">
                        {errors.password}
                      </Text>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Action */}
              <div className="text-center">
                <LoadingButton
                  type="submit"
                  fullWidth
                  className="btn btn-lg btn-primary w-100 mb-5"
                  disabled={!isValid || isSubmitting}
                  loading={loading}>
                  <Text variant="body1" className="indicator-label" isBold>
                    Continua
                  </Text>
                </LoadingButton>
              </div>
              {/* end::Action */}
            </form>
          )}
        </Formik>
      </div>
    </Container>
  );
};

export default Login;
