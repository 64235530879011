import {
  FETCHING_USER_ERROR,
  FETCHING_USER_STATUS,
  FETCH_USER_SUCCESS,
  LOGOUT_USER,
  StateUserInterface,
} from "../reducer/authReducer";
import { fetchingStatus } from "../reducer/helper";

// const fetchUserById = createAsyncThunk(
//   "users/fetchByIdStatus",
//   async (userId, thunkAPI) => {
//     const response = await userAPI.fetchById(userId);
//     return response.data;
//   }
// );

export const logginUser = (user: string, password: string): any => {
  return async (dispatch: any) => {
    if (!(user && password)) {
      return;
    }
    dispatch(FETCHING_USER_STATUS(fetchingStatus.FETCHING));
    const response = {
      ok: true,
    };
    if (response.ok) {
      const userObject: StateUserInterface = {
        token: "test",
        userId: "test",
        userName: "test",
        userAccess: [1, 2, 3],
        userRole: "2",
        userRoutesAccess: ["About"],
      };
      dispatch(FETCH_USER_SUCCESS(userObject));
    } else {
      dispatch(FETCHING_USER_ERROR());
    }
  };
};

export const logoutUser = (): any => {
  return async (dispatch: any) => {
    document.location.reload();
    dispatch(LOGOUT_USER());
  };
};
