import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";

const DatePickerComponent = ({
  label,
  placeholder,
  handleChangeDate,
  value = new Date(),
  disabled,
  className,
  withVerticalSpacer,
}: {
  label?: string;
  placeholder?: string;
  handleChangeDate?: (newValue: Date | null) => void;
  value?: Date;
  disabled?: boolean;
  className?: string;
  withVerticalSpacer?: boolean;
}) => {
  const handleChange = (newValue: Date | null) => {
    if (newValue) {
      handleChangeDate && handleChangeDate(newValue);
    }
  };
  return (
    <div className={`w-100 ${withVerticalSpacer ? "px-1" : ""} ${className}`}>
      <DatePicker
        value={value}
        inputFormat="dd MMM yyyy"
        mask=""
        onChange={handleChange}
        disabled={disabled}
        renderInput={(params) => (
          <div className="fv-row mb-7">
            {label && (
              <label className="required fw-bold fs-6 mb-2">{label}</label>
            )}
            <TextField
              className="form-control form-control-solid mb-3 mb-lg-0"
              placeholder={placeholder}
              {...params}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export default DatePickerComponent;
