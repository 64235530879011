import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import InputComponent from "../components/InputComponent";
import DatePickerComponent from "../components/DatePickerComponent";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../../helpers/apiHelper";
import useGlobalCtx from "../../context/GlobalContext/useGlobalCtx";

interface initialValues {
  nume: string;
  prenume: string;
  adresa: string;
  serieBuletin: string;
  numarBuletin: string;
  cnp: string;
  nrResedinta: string;
  nrParcare: string;
  dataStart: Date;
  dataExpirare: Date;
}

const initialValues: initialValues = {
  nume: "",
  prenume: "",
  adresa: "",
  serieBuletin: "",
  numarBuletin: "",
  cnp: "",
  nrResedinta: "",
  nrParcare: "",
  dataStart: new Date(),
  dataExpirare: new Date(),
};

const reviewSchema = Yup.object({
  nume: Yup.string().required("Campul trebuie completat"),
  prenume: Yup.string().required("Campul trebuie completat"),
  adresa: Yup.string().required("Campul trebuie completat"),
  serieBuletin: Yup.string().required("Campul trebuie completat"),
  numarBuletin: Yup.string().required("Campul trebuie completat"),
  cnp: Yup.string().required("Campul trebuie completat"),
  nrResedinta: Yup.string().required("Campul trebuie completat"),
  nrParcare: Yup.string().required("Campul trebuie completat"),
  dataStart: Yup.date().required("Campul trebuie completat"),
  dataExpirare: Yup.date().required("Campul trebuie completat"),
});

const AddPark = () => {
  const navigate = useNavigate();

  const { showToast } = useGlobalCtx()!;
  const [isLoading, setIsLoading] = useState(false);
  const [formikValues, setFormikValues] =
    useState<initialValues>(initialValues);

  const { id } = useParams() || {};

  useEffect(() => {
    if (id) {
      console.log(id);
      setFormikValues({ ...initialValues, nume: "sef" });
    } else {
      setFormikValues(initialValues);
    }
  }, [id]);

  const handleSaveUserDetails = async (values: initialValues) => {
    showToast("Ati adaugat o parcare", "success");
    console.log(values);
    try {
      setIsLoading(true);
      await apiRequest("/addContract", "POST", values);
      navigate("/table/parcari");

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showToast("A aparut o eroare", "error");

      console.log(error);
    }
  };
  return (
    <div className="card">
      <div className="card-body d-flex flex-column p-8">
        <h1 className="mb-5">Adauga parcare</h1>
        <Formik
          enableReinitialize={true}
          initialValues={formikValues}
          validationSchema={reviewSchema}
          onSubmit={handleSaveUserDetails}>
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            values,
            errors,
            touched,
          }) => (
            <form
              id="kt_modal_add_user_form"
              className="form"
              onSubmit={handleSubmit}
              noValidate>
              <div className="d-flex flex-column scroll-y me-n7 pe-7">
                <InputComponent
                  label="Nume"
                  name="nume"
                  placeholder="Adauga nume"
                  error={errors.nume}
                  value={values.nume}
                  touched={touched.nume}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                />
                <InputComponent
                  label="Prenume"
                  name="prenume"
                  placeholder="Adauga prenume"
                  error={errors.prenume}
                  value={values.prenume}
                  touched={touched.prenume}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                />
                <InputComponent
                  label="Adresa"
                  name="adresa"
                  placeholder="Adauga adresa"
                  error={errors.adresa}
                  value={values.adresa}
                  touched={touched.adresa}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                />
                <div className="d-md-flex">
                  <InputComponent
                    label="Serie buletin"
                    name="serieBuletin"
                    placeholder="Adauga serie buletin"
                    error={errors.serieBuletin}
                    value={values.serieBuletin}
                    touched={touched.serieBuletin}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    withVerticalSpacer
                  />
                  <InputComponent
                    label="Numar buletin"
                    name="numarBuletin"
                    placeholder="Adauga numar buletin"
                    error={errors.numarBuletin}
                    value={values.numarBuletin}
                    touched={touched.numarBuletin}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    withVerticalSpacer
                  />
                </div>
                <InputComponent
                  label="CNP"
                  name="cnp"
                  placeholder="Adauga CNP"
                  error={errors.cnp}
                  value={values.cnp}
                  touched={touched.cnp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  maxLength={13}
                />
                <div className="d-md-flex">
                  <InputComponent
                    label="Nr. resedinta"
                    name="nrResedinta"
                    placeholder="Adauga Nr. resedinta"
                    error={errors.nrResedinta}
                    value={values.nrResedinta}
                    touched={touched.nrResedinta}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    withVerticalSpacer
                  />
                  <InputComponent
                    label="Nr. parcare"
                    name="nrParcare"
                    placeholder="Adauga Nr. parcare"
                    error={errors.nrParcare}
                    value={values.nrParcare}
                    touched={touched.nrParcare}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    withVerticalSpacer
                  />
                </div>
                <div className="d-md-flex">
                  <DatePickerComponent
                    label="Data start"
                    withVerticalSpacer
                    value={values.dataStart}
                    disabled={isSubmitting}
                    handleChangeDate={(value) =>
                      setFieldValue("dataStart", value)
                    }
                  />
                  <DatePickerComponent
                    label="Data expirare"
                    withVerticalSpacer
                    value={values.dataExpirare}
                    disabled={isSubmitting}
                    handleChangeDate={(value) =>
                      setFieldValue("dataExpirare", value)
                    }
                  />
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting || isLoading}
                  className="btn btn-large btn-color-primary btn-active-light-primary">
                  {isSubmitting ? "Se salveaza..." : "SALVEAZA"}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddPark;
