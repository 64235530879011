import axios from "axios";

type MethodType = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

const axiosService = axios.create({
  // baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export const apiRequest = async (
  url: string,
  method: MethodType = "GET",
  transferObject?: any,
  customHeader?: object,
) => {
  const token = localStorage.getItem("token");
  const Authorization = token ?? "";

  return await axiosService.request({
    url: `${process.env.REACT_APP_API_URL}${url}`,
    method: method,
    data: transferObject,
    headers: {
      Authorization,
      ...customHeader,
    },
  });
};
