import React, { FC } from "react";
import { MixedWidget1 } from "../../components/misc/partials/widgets";
import { categories } from "../../config";
import { KTSVG } from "../../helpers/KTSVG";

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className="row g-5 g-xl-8">
      {/* begin::Col */}
      {categories.map(
        ({
          categories,
          className,
          color,
          subtitle,
          subtitleValue,
          title,
          mapLink,
          tableLink,
          addLink,
        }) => {
          return (
            <div
              // className="col-xl-4"
              key={title}>
              <MixedWidget1
                className={className}
                color={color}
                title={title}
                subtitle={subtitle}
                subtitleValue={subtitleValue}
                categories={categories}
                buttonAddLink={addLink}>
                <div
                  className="d-flex flex-row justify-content-between shadow-xs card-rounded mx-9 px-6 py-9 bg-white"
                  style={{
                    marginTop: categories ? 0 : -100,
                    marginBottom: categories ? 25 : 0,
                  }}>
                  <a href={mapLink} className="btn btn-sm btn-light">
                    <KTSVG
                      path="/media/icons/duotune/maps/map002.svg"
                      className="svg-icon-3"
                    />
                    Harta
                  </a>
                  <a href={tableLink} className="btn btn-sm btn-light">
                    <KTSVG
                      path="/media/icons/duotune/general/gen019.svg"
                      className="svg-icon-3"
                    />{" "}
                    Tabel
                  </a>
                </div>
              </MixedWidget1>
            </div>
          );
        },
      )}
      {/* end::Col */}
    </div>
    {/* end::Row */}{" "}
  </>
);

const DashboardWrapper: FC = () => {
  return <DashboardPage />;
};

export { DashboardWrapper };
