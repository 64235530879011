import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { Error404 } from "../pages/Error/Error404";
import { Error500 } from "../pages/Error/Error500";
import { ErrorsPage } from "../pages/Error/ErrorPage";
import Login from "../pages/Login";
import AddPark from "../pages/Park/AddPark";
import ParkTableWrapper from "../pages/Park/ParkTableWrapper";
import Layout from "./Layout";
import ProtectedRoute from "./ProtectedRoute";
import ScrollToTop from "./scrollToTop";

const initialMainRoute = "/dashboard";

const MainRoutes = () => {
  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  // let location = useLocation();
  // return <Navigate to="/login" state={{ from: location }} />;

  const token = "sef";

  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<Navigate to={initialMainRoute} />} />

          <Route path={"error/*"} element={<ErrorsPage />} />
          <Route path={"/error/500"} element={<Error500 />} />
          <Route path={"*"} element={<Error404 />} />

          <Route element={<ProtectedRoute isAuthorized={!!token} />}>
            <Route path={initialMainRoute} element={<DashboardWrapper />} />
            <Route path="/gestioneaza-parcare" element={<AddPark />} />
            <Route path="/gestioneaza-parcare/:id" element={<AddPark />} />
            <Route path="/table/parcari" element={<ParkTableWrapper />} />
            <Route
              path="/table/parcari/:parkId"
              element={<ParkTableWrapper />}
            />
          </Route>
          <Route
            element={
              <ProtectedRoute
                isAuthorized={!token}
                redirectPath={initialMainRoute}
              />
            }>
            <Route path={"/login"} element={<Login />} />
          </Route>
        </Routes>
      </Layout>
    </Router>
  );
};

export default MainRoutes;
