import React from "react";
import TableProvider from "../../components/tables/tableContext/TableProvider";
import ParkTable from "./ParkTable";

function ParkTableWrapper() {
  return (
    <TableProvider>
      <ParkTable className="mb-5 mb-xl-8" />
    </TableProvider>
  );
}

export default ParkTableWrapper;
