import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { fetchingStatus } from "./helper";

export interface StateUserInterface {
  token: null | string;
  userId: null | string;
  userName: null | string;
  userAccess: null | number[];
  userRoutesAccess: string[];
  userRole: null | string;
  userFetchingStatus?: null | string;
}

export const defaultStateUser: StateUserInterface = {
  token: null,
  userId: null,
  userName: null,
  userAccess: null,
  userRole: null,
  userFetchingStatus: null,
  userRoutesAccess: [],
};

const auth = createSlice({
  name: "auth",
  initialState: defaultStateUser,
  reducers: {
    FETCHING_USER_STATUS(
      state,
      action: PayloadAction<{ userFetchingStatus: string }>,
    ) {
      return {
        ...state,
        userFetchingStatus: action.payload.userFetchingStatus,
      };
    },
    FETCH_USER_SUCCESS(state, action: PayloadAction<StateUserInterface>) {
      return {
        ...state,
        ...action.payload,
        userFetchingStatus: fetchingStatus.FETCHED,
      };
    },
    LOGOUT_USER(state) {
      return {
        ...state,
        ...defaultStateUser,
      };
    },
    FETCHING_USER_ERROR(state) {
      return {
        ...state,
        ...defaultStateUser,
        userFetchingStatus: fetchingStatus.ERROR,
      };
    },
  },
});

export const {
  FETCHING_USER_STATUS,
  FETCH_USER_SUCCESS,
  LOGOUT_USER,
  FETCHING_USER_ERROR,
} = auth.actions;

export default auth.reducer;
