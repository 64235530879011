import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../../../helpers/KTSVG";

type Props = {
  className: string;
  color: string;
  disableDropdown?: boolean;
  title?: string;
  subtitle?: string;
  subtitleValue?: string;
  categories?: {
    Icon?: JSX.Element;
    title?: string;
    subtitle?: string;
    value?: string;
    isIncreasing?: boolean;
    path?: string;
  }[];
  children?: JSX.Element;
  buttonAddLink?: string;
};

const MixedWidget1: React.FC<Props> = ({
  className,
  color,
  title = "",
  subtitle,
  subtitleValue,
  categories,
  children,
  buttonAddLink,
}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className="card-body p-0">
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
          {/* begin::Heading */}
          <div className="d-flex flex-stack">
            <div className="d-flex flex-row w-100 justify-content-between align-items-center">
              <h3 className="m-0 text-white fw-bolder fs-3">{title}</h3>
              {buttonAddLink && (
                <Link to={buttonAddLink}>
                  <button
                    type="button"
                    className="btn btn-sm btn-light-primary fw-bolder">
                    <Typography className="text-black">Adauga</Typography>
                  </button>
                </Link>
              )}
            </div>
          </div>
          {/* end::Heading */}
          {/* begin::Balance */}
          {subtitle && (
            <div className="d-flex text-center flex-column text-white pt-8">
              <span className="fw-bold fs-5">{subtitle}</span>
              <span className="fw-bolder fs-2x pt-1">{subtitleValue}</span>
            </div>
          )}
          {/* end::Balance */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        {categories && (
          <div
            className="shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white"
            style={{ marginTop: "-100px" }}>
            {/* begin::Item */}
            {categories.map(
              ({ Icon, subtitle, title, value, isIncreasing, path }, index) => {
                return (
                  <div className="d-flex align-items-center mb-4" key={index}>
                    {/* begin::Symbol */}
                    <div className="symbol symbol-45px w-40px me-5">
                      <span className="symbol-label bg-lighten">{Icon}</span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Description */}
                    <div className="d-flex align-items-center flex-wrap w-100">
                      {/* begin::Title */}
                      <div className="mb-1 pe-3 flex-grow-1">
                        <a
                          href={path}
                          className="fs-5 text-gray-800 text-hover-primary fw-bolder">
                          {title}
                        </a>
                        <div className="text-gray-400 fw-bold fs-7">
                          {subtitle}
                        </div>
                      </div>
                      {/* end::Title */}
                      {/* begin::Label */}
                      <div className="d-flex align-items-center">
                        <div className="fw-bolder fs-5 text-gray-800 pe-1">
                          {value}
                        </div>
                        {isIncreasing !== undefined ? (
                          isIncreasing ? (
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr066.svg"
                              className="svg-icon-5 svg-icon-success ms-1"
                            />
                          ) : (
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr065.svg"
                              className="svg-icon-5 svg-icon-danger ms-1"
                            />
                          )
                        ) : undefined}
                      </div>
                      {/* end::Label */}
                    </div>
                    {/* end::Description */}
                  </div>
                );
              },
            )}
            {/* end::Item */}
          </div>
        )}

        {/* end::Items */}
        {children}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { MixedWidget1 };
