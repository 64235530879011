import { KTSVG } from "./helpers/KTSVG";

export const categories = [
  {
    className: "card-xl-stretch mb-xl-8",
    color: "primary",
    title: "Parcari",
    subtitle: "Total Locuri",
    subtitleValue: "1391",
    tableLink: "/table/parcari",
    mapLink: "/map/parcari",
    addLink: "/gestioneaza-parcare",
    categories: [
      {
        Icon: (
          <KTSVG
            path="/media/icons/duotune/general/gen005.svg"
            className="svg-icon-1"
          />
        ),
        title: "Locuri ocupate",
        subtitle: "",
        value: "1284",
        path: "/table/parcari&status=locuri-ocupate",
      },
      {
        Icon: (
          <KTSVG
            path="/media/icons/duotune/general/gen005.svg"
            className="svg-icon-1"
          />
        ),
        title: "Locuri libere",
        subtitle: "",
        value: "107",
        path: "/table/parcari&status=locuri-libere",
      },
      {
        Icon: (
          <KTSVG
            path="/media/icons/duotune/general/gen005.svg"
            className="svg-icon-1"
          />
        ),
        title: "Expira in 30 de zile",
        subtitle: "",
        value: "52",
        path: "/table/parcari&status=expira-in-30-de-zile",
      },
      {
        Icon: (
          <KTSVG
            path="/media/icons/duotune/general/gen005.svg"
            className="svg-icon-1"
          />
        ),
        title: "Expirate",
        subtitle: "",
        value: "53",
        path: "/table/parcari&status=expirate",
      },
    ],
  },
  // item
  // {
  //   className: "card-xl-stretch mb-xl-8",
  //   color: "success",
  //   title: "Indicatoare",
  //   subtitle: "Total indicatoare",
  //   subtitleValue: "1391",
  //   tableLink: "/table/indicatoare",
  //   mapLink: "/map/indicatoare",
  //   categories: [
  //     {
  //       Icon: (
  //         <KTSVG
  //           path="/media/icons/duotune/general/gen005.svg"
  //           className="svg-icon-1"
  //         />
  //       ),
  //       title: "Buna",
  //       subtitle: "",
  //       value: "107",
  //       path: "/table/indicatoare&status=buna",
  //     },
  //     {
  //       Icon: (
  //         <KTSVG
  //           path="/media/icons/duotune/general/gen005.svg"
  //           className="svg-icon-1"
  //         />
  //       ),
  //       title: "Putin defect",
  //       subtitle: "",
  //       value: "52",
  //       path: "/table/indicatoare&status=putin-defect",
  //     },
  //     {
  //       Icon: (
  //         <KTSVG
  //           path="/media/icons/duotune/general/gen005.svg"
  //           className="svg-icon-1"
  //         />
  //       ),
  //       title: "Defect",
  //       subtitle: "",
  //       value: "53",
  //       path: "/table/indicatoare&status=defect",
  //     },
  //     {
  //       Icon: (
  //         <KTSVG
  //           path="/media/icons/duotune/general/gen005.svg"
  //           className="svg-icon-1"
  //         />
  //       ),
  //       title: "Necesita schimbare",
  //       subtitle: "",
  //       value: "1284",
  //       path: "/table/indicatoare&status=necesita-schimbare",
  //     },
  //     {
  //       Icon: (
  //         <KTSVG
  //           path="/media/icons/duotune/general/gen005.svg"
  //           className="svg-icon-1"
  //         />
  //       ),
  //       title: "Lipsa",
  //       subtitle: "",
  //       value: "2",
  //       path: "/table/indicatoare&status=lipsa",
  //     },
  //   ],
  // },
  // // item
  // {
  //   className: "card-xl-stretch mb-xl-8",
  //   color: "info",
  //   title: "Mobilier",
  //   subtitle: "Total Mobilier",
  //   subtitleValue: "2",
  //   tableLink: "/table/mobilier",
  //   mapLink: "/map/mobilier",
  //   categories: [
  //     {
  //       Icon: (
  //         <KTSVG
  //           path="/media/icons/duotune/general/gen005.svg"
  //           className="svg-icon-1"
  //         />
  //       ),
  //       title: "Buna",
  //       subtitle: "",
  //       value: "107",
  //       path: "/table/mobilier&status=buna",
  //     },
  //     {
  //       Icon: (
  //         <KTSVG
  //           path="/media/icons/duotune/general/gen005.svg"
  //           className="svg-icon-1"
  //         />
  //       ),
  //       title: "Putin defect",
  //       subtitle: "",
  //       value: "52",
  //       path: "/table/mobilier&status=putin-defect",
  //     },
  //     {
  //       Icon: (
  //         <KTSVG
  //           path="/media/icons/duotune/general/gen005.svg"
  //           className="svg-icon-1"
  //         />
  //       ),
  //       title: "Defect",
  //       subtitle: "",
  //       value: "53",
  //       path: "/table/mobilier&status=defect",
  //     },
  //     {
  //       Icon: (
  //         <KTSVG
  //           path="/media/icons/duotune/general/gen005.svg"
  //           className="svg-icon-1"
  //         />
  //       ),
  //       title: "Necesita schimbare",
  //       subtitle: "",
  //       value: "1284",
  //       path: "/table/mobilier&status=necesita-schimbare",
  //     },
  //     {
  //       Icon: (
  //         <KTSVG
  //           path="/media/icons/duotune/general/gen005.svg"
  //           className="svg-icon-1"
  //         />
  //       ),
  //       title: "Lipsa",
  //       subtitle: "",
  //       value: "2",
  //       path: "/table/mobilier&status=lipsa",
  //     },
  //   ],
  // },
  // // item
  // {
  //   className: "card-xl-stretch mb-xl-8",
  //   color: "primary",
  //   title: "Imobile",
  //   subtitle: "Total Imobile",
  //   subtitleValue: "7",
  //   tableLink: "/table/imobile",
  //   mapLink: "/map/imobile",
  // },
  // // item
  // {
  //   className: "card-xl-stretch mb-xl-8",
  //   color: "danger",
  //   title: "Locuri de Joaca",
  //   subtitle: "Numar Locuri de Joaca",
  //   subtitleValue: "2",
  //   tableLink: "/table/locuriJoaca",
  //   mapLink: "/map/locuriJoaca",
  // },
  // // item
  // {
  //   className: "card-xl-stretch mb-xl-8",
  //   color: "danger",
  //   title: "Strazi",
  //   subtitle: "Numar strazi",
  //   subtitleValue: "5",
  //   tableLink: "/table/strazi",
  //   mapLink: "/map/strazi",
  // },
];
