import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link className="text-reset h-100 w-100" to="/">
      <Typography variant="h6" noWrap component="div">
        Manager patrimoniu
      </Typography>
    </Link>
  );
};

export default Logo;
