import { Trans } from "react-i18next";
import { Typography, TypographyProps } from "@mui/material";

type Text = {
  children?: string | number;
  i18nKey?: string;
  i18nVariable?: object;
  isBold?: boolean;
};

export type TextProps = Text & TypographyProps;

const Text = ({
  children,
  i18nKey,
  i18nVariable,
  isBold,
  ...otherProps
}: TextProps) => {
  const customizeProps = {
    ...otherProps,
    className: `${isBold && "fw-bolder"} ${otherProps.className}`,
  };

  const i18nKeyValue = i18nKey ?? children?.toString();

  return (
    <Typography {...customizeProps}>
      <Trans components={{ bold: <strong /> }} values={i18nVariable}>
        {i18nKeyValue}
      </Trans>
    </Typography>
  );
};

export default Text;
