import { FC } from "react";

const Error404: FC = () => {
  return (
    <>
      <h1 className="fw-bolder fs-4x text-gray-700 mb-10">Page Not Found</h1>

      <div className="fw-bold fs-3 text-gray-400 mb-15">
        The page you looked not found! <br /> Plan your blog post by choosing a
        topic
      </div>
      <div
        className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_API_URL}/media/illustrations/sketchy-1/17.png)`,
        }}
      />
    </>
  );
};

export { Error404 };
