import AppContainerWrapper from "../components/AppContainerWrapper";
import Toast from "../components/Toast";
import useMeasure from "../hooks/useMeasure";
import { Footer } from "./Footer";
import NavbarPersistWrapper from "./Navbar/NavbarPersistWrapper";

const Layout = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const { ref, size, windowSize } = useMeasure();

  const token = "sefg";

  const height = windowSize.height - size.height - 64;

  return (
    <div className="w-100 vh-100">
      <Toast />
      {token ? (
        <NavbarPersistWrapper>
          <AppContainerWrapper style={{ minHeight: height }}>
            {children}
          </AppContainerWrapper>
          <div ref={ref}>
            <Footer />
          </div>
        </NavbarPersistWrapper>
      ) : (
        children
      )}
    </div>
  );
};

export default Layout;
