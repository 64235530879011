import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import TableHeaderCategories from "../../components/tables/components/TableHeaderCategories";
import { TableLoading } from "../../components/tables/components/TableLoading";
import TablePaginationComponent from "../../components/tables/components/TablePagination";
import useTableCtx from "../../components/tables/tableContext/useTableCtx";
import { apiRequest } from "../../helpers/apiHelper";

import { KTSVG } from "../../helpers/KTSVG";
// import { TablePagination } from "../tables/components/TablePagination";
// 9 11
function ParkTable({ className }: { className?: string }) {
  // @ts-ignore
  const {
    tableSearch,
    setTableSearch,
    tablePagination,
    setTablePagination,
    setTableData,
    setTableLoading,
    tableLoading,
    tableData,
  } = useTableCtx()!;

  console.log("tableSearch", tableSearch);

  useEffect(() => {
    const getTableParkData = async () => {
      try {
        setTableLoading(true);
        const {
          data: { contracte },
        } = await apiRequest(
          `/allContracte/${tablePagination?.perPage}?page=${
            tablePagination?.page + 1
          }`,
          "GET",
        );
        setTablePagination((prev: any) => ({
          ...prev,
          totalItems: contracte.total,
        }));
        setTableData(contracte.data);
        setTableLoading(false);
      } catch (error) {
        setTableLoading(false);
        console.log("tableError", error);
      }
    };
    getTableParkData();
  }, [
    setTableData,
    setTablePagination,
    tablePagination.page,
    tablePagination.perPage,
  ]);

  //   adresa: "dsadsad"
  // cnp: "1231232131312"
  // dataExpirare: "2022-06-24"
  // dataStart: "2022-06-24"
  // idUser: 1
  // nrParcare: "213"
  // nrResedinta: "231"
  // numarBuletin: "adas"
  // nume: "dsad"
  // prenume: "sdad"
  // serieBuletin: "dsads"

  const getCategories = [
    {
      category: "Nume si prenume",
    },
    {
      category: "Nr parcare",
    },
    { category: "Adresa" },
    { category: "Data expirare" },
  ];

  const handleChangePage = (page: number) => {
    setTablePagination({
      ...tablePagination,
      page,
    });
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setTablePagination({
      ...tablePagination,
      page: 0,
      perPage: rowsPerPage,
    });
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <TableHeader />
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <TableHeaderCategories
              tableHeaderCategories={getCategories}
              setTableSearch={setTableSearch}
              showSearchForCategories={false}
            />
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {tableData.map((item, index) => {
                return <TableRow key={index} item={item} index={index} />;
              })}
            </tbody>
            <TablePaginationComponent
              page={tablePagination.page}
              perPage={tablePagination.perPage}
              totalItems={tablePagination.totalItems}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* <TablePagination /> */}

      {tableLoading && <TableLoading />}

      {/* begin::Body */}
    </div>
  );
}

const TableHeader = () => {
  const { tablePagination } = useTableCtx()!;
  return (
    <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <span className="card-label fw-bolder fs-3 mb-1">Total parcari</span>
        <span className="text-muted mt-1 fw-bold fs-7">
          {tablePagination?.totalItems}
        </span>
      </h3>
      <div className="card-toolbar">
        <Link to={"/gestioneaza-parcare"}>
          <div className="btn btn-sm btn-light-primary">
            <KTSVG
              path="/media/icons/duotune/arrows/arr075.svg"
              className="svg-icon-2"
            />
            Adauga parcare
          </div>
        </Link>
      </div>
    </div>
  );
};

const TableRow = ({
  item,
  index,
  handleView,
  handleEdit,
  handleDelete,
}: {
  item: any;
  index?: number;
  handleView?: (value: string) => void;
  handleEdit?: (value: string) => void;
  handleDelete?: (value: string) => void;
}) => {
  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column">
            <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
              {item?.nume} {item?.prenume}
            </span>
          </div>
        </div>
      </td>
      <td>
        <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
          {item?.nrParcare}
        </span>
      </td>
      <td>
        <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
          {item?.adresa}
        </span>
      </td>
      <td>
        <span className="badge badge-light-warning fs-7 fw-bold">
          {item?.dataExpirare}
        </span>
      </td>
      <td className="text-end">
        <button
          onClick={() => handleView && handleView(item.id)}
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
          <KTSVG
            path="/media/icons/duotune/general/gen019.svg"
            className="svg-icon-3"
          />
        </button>
        <button
          onClick={() => handleEdit && handleEdit(item.id)}
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
          <KTSVG
            path="/media/icons/duotune/art/art005.svg"
            className="svg-icon-3"
          />
        </button>
        <button
          onClick={() => handleDelete && handleDelete(item.id)}
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
          <KTSVG
            path="/media/icons/duotune/general/gen027.svg"
            className="svg-icon-3"
          />
        </button>
      </td>
    </tr>
  );
};

export default ParkTable;
