import { AlertColor } from "@mui/material";
import React, { useState } from "react";
import { GlobalContext } from "./GlobalContext";

type Props = {
  children: React.ReactNode;
};

const GlobalProvider = ({ children }: Props) => {
  const [theme, setTheme] = useState(true);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState<AlertColor | undefined>("info");
  const [toastDuration, setToastDuration] = useState<number | null>(null);

  const showToast = (message: string, type?: AlertColor, duration?: number) => {
    setToastMessage(message);
    setToastType(type);
    duration && setToastDuration(duration);
  };

  const hideToast = () => {
    setToastMessage("");
    setToastType(undefined);
    setToastDuration(null);
  };

  return (
    <GlobalContext.Provider
      value={{
        theme,
        setTheme,
        toastMessage,
        toastType,
        toastDuration,
        showToast,
        hideToast,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
