import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import authReducer from "../reducer/authReducer";
import appReducer from "../reducer/appReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    appConfig: appReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
