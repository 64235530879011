import React, { useState } from "react";
import { TableCategories, TableContext, TableData } from "./TableContext";

type Props = {
  children: React.ReactNode;
};

const TableProvider = ({ children }: Props) => {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableHeader, setTableHeader] = useState<TableCategories[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    totalItems: 20,
    perPage: 5,
  });
  const [tableSearch, setTableSearch] = useState<object>({});

  const deleteItem = (id: string | number) => {
    setTableData(tableData.filter((item) => item.id !== id));
  };

  return (
    <TableContext.Provider
      value={{
        tableData,
        setTableData,
        tableHeader,
        setTableHeader,
        tableLoading,
        setTableLoading,
        tablePagination,
        setTablePagination,
        tableSearch,
        setTableSearch,
        deleteItem,
      }}>
      {children}
    </TableContext.Provider>
  );
};

export default TableProvider;
