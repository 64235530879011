import { createSlice } from "@reduxjs/toolkit";

interface InitialAppState {
  isAppFetched: boolean;
}

const initialAppState: InitialAppState = {
  isAppFetched: true,
};

const appConfig = createSlice({
  name: "appConfig",
  initialState: initialAppState,
  reducers: {
    SET_APP_FETCHED: (state) => {
      return {
        ...state,
        isAppFetched: true,
      };
    },
  },
});

export const { SET_APP_FETCHED } = appConfig.actions;

export default appConfig.reducer;
