import React from "react";

export interface TableData {
  id: number;
  data: any[];
}

export interface TableCategories {
  category: string;
  minW: string;
}

type TableContextType = {
  tableData: TableData[];
  setTableData: (value: TableData[]) => void;
  tableHeader: TableCategories[];
  setTableHeader: (value: TableCategories[]) => void;
  tableLoading: boolean;
  setTableLoading: (value: boolean) => void;
  tablePagination: { page: number; totalItems: number; perPage: number };
  setTablePagination: (value: any) => void;
  tableSearch: object;
  setTableSearch: (value: object) => void;
  deleteItem: (value: string | number) => void;
};

export const TableContext = React.createContext<TableContextType | undefined>(
  undefined,
);
