import React from "react";
import clsx from "clsx";
import { Input } from "@mui/material";

function InputComponent({
  label,
  value,
  name,
  placeholder,
  error,
  onChange,
  onBlur,
  touched,
  disabled,
  className,
  maxLength,
  withVerticalSpacer,
}: {
  label?: string;
  value: string;
  name?: string;
  placeholder?: string;
  error?: string;
  onChange?: any;
  onBlur?: any;
  touched?: boolean;
  disabled?: boolean;
  className?: string;
  maxLength?: number;
  withVerticalSpacer?: boolean;
}) {
  return (
    <div
      className={`fv-row mb-7 w-100 ${
        withVerticalSpacer ? "px-1" : ""
      } ${className}`}>
      {/* begin::Label */}
      {label && <label className="required fw-bold fs-6 mb-2">{label}</label>}
      {/* end::Label */}

      {/* begin::Input */}
      <Input
        placeholder={placeholder}
        type="text"
        name={name}
        value={value}
        disableUnderline
        className={clsx(
          "form-control form-control-solid mb-3 mb-lg-0",
          { "is-invalid": error && touched },
          {
            "is-valid": !error && touched,
          },
        )}
        onBlur={onBlur}
        onChange={onChange}
        autoComplete="off"
        disabled={disabled}
        inputProps={{ maxLength }}
      />
      {error && touched && (
        <span role="alert" className="text-danger">
          {error}
        </span>
      )}
      {/* end::Input */}
    </div>
  );
}

export default InputComponent;
