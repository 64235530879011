import React from "react";
import { TablePagination } from "@mui/material";

function TablePaginationComponent({
  totalItems,
  perPage,
  page = 0,
  onChangePage,
  onChangeRowsPerPage,
}: {
  totalItems: number;
  perPage: number;
  page?: number;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (rows: number) => void;
}) {
  const handleChangePage = (event: unknown, newPage: number) => {
    onChangePage && onChangePage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChangeRowsPerPage &&
      onChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="tfoot"
      count={totalItems}
      rowsPerPage={perPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage={<span className="mb-0">Rows:</span>}
      labelDisplayedRows={({ page }) => {
        return <span className="mb-0">Page: {page + 1}</span>;
      }}
      backIconButtonProps={{
        color: "primary",
      }}
      nextIconButtonProps={{ color: "primary" }}
      SelectProps={{
        inputProps: {
          "aria-label": "page number",
        },
      }}
      showFirstButton={true}
      showLastButton={true}
      //ActionsComponent={TablePaginationActions}
      //component={Box}
    />
  );
}

export default TablePaginationComponent;
