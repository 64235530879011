import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import useGlobalCtx from "../context/GlobalContext/useGlobalCtx";
import { Typography } from "@mui/material";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Toast({ defaultDuration = 3000 }) {
  const { toastMessage, toastType, toastDuration, hideToast } = useGlobalCtx()!;

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    hideToast();
  };

  useEffect(() => {
    if (toastMessage) {
      setTimeout(() => {
        hideToast();
      }, toastDuration ?? defaultDuration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastMessage, toastDuration, defaultDuration]);

  const getToastType = () => {
    return toastType && toastType;
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={!!toastMessage}
        autoHideDuration={toastDuration ?? defaultDuration}
        onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={getToastType()}
          sx={{ width: "100%" }}>
          <Typography>{toastMessage}</Typography>
        </Alert>
      </Snackbar>
    </Stack>
  );
}
